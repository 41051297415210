import React from "react";
import "./App.css";
import Home from "./views/Home";

function App() {
  return (
    <div className="bg-[#ffffff] dark:bg-[#1c1c1c]">
      <Home/>
    </div>
  );
}

export default App;
