import React from "react";
import Navbar from "../components/layout/Navbar";

function Home() {
  return (
    <div className="bg-[#ffffff] dark:bg-[#1c1c1c]">
      <Navbar />
      <div className="bg-[#E7FFED]  flex items-center pb-8">
        <div className="w-1/2 pl-56">
          <p className="text-[#458764] text-4xl font-bold" >
            Ein LNG-Terminal lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh
          </p>
          <p className="text-xl font-medium mt-8">
            Ein LNG-Terminal lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh
          </p>
          <div className="mt-8 flex items-center">
            <p className="mr-4 text-xl font-bold text-[#458764] ">
              Mehr erfahren
            </p>
            <p className="bg-transparent text-[#458764] border-[#458764] border p-1 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
              </svg>
            </p>
          </div>
        </div>
        <div className="w-1/2">
          <img src="img/illustration.png" width="90%" className="float-right" />
        </div>
      </div>

      <div className="section px-52 pt-10">
        
      </div>
    </div>
  );
}

export default Home;
